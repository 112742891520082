@charset "UTF-8";
/* CSS Document */
/*---------------------------ENTREPRISE / QUI SOMMES-NOUS? -------------------------*/
.main-entreprise h2 {
  text-transform: uppercase;
  letter-spacing: 6px;
  text-align: center; }

.main-entreprise h3 {
  font-family: NexaRustSans-Book;
  text-transform: uppercase;
  color: #b19650;
  font-size: 15px;
  font-weight: 600; }

.main-entreprise #valeurs h3, .main-entreprise #valeurs p {
  max-width: 700px;
  margin: 0 auto; }

.main-entreprise #valeurs {
  padding: 150px 0;
  background: url("../images/background-valeurs.png") no-repeat top center #000;
  background-size: cover; }

.main-entreprise #valeurs h3 {
  margin-top: 90px;
  line-height: 26px; }

.main-entreprise #valeurs p {
  line-height: 30px; }

#frise-histoire {
  position: relative;
  background-color: #000;
  margin-bottom: 100px; }

#frise-histoire::after {
  content: "";
  background: url("../images/dot-frise.png") repeat-y center;
  display: block;
  width: 5px;
  height: 100%;
  position: absolute;
  top: 40px;
  left: 50%;
  z-index: 0; }

.main-entreprise .field-collection-container {
  border-bottom: none; }

.main-entreprise .field-collection-view {
  border-bottom: none; }

.main-entreprise .field-collection-view:nth-child(even) .item .description {
  order: -1;
  justify-content: flex-end; }

.main-entreprise .field-collection-view:nth-child(even) .item .image {
  justify-content: flex-start; }

.main-entreprise .field-collection-view .item {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom -60px right;
  position: relative;
  overflow: hidden; }

.main-entreprise .field-collection-view .item .wrap {
  display: flex;
  padding: 30px 0; }

.main-entreprise .field-collection-view:nth-child(even) .item {
  background-position: left; }

.main-entreprise .field-collection-view .item .image {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 40px;
  box-sizing: border-box; }

.main-entreprise .field-collection-view .item .description {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 40px; }

.main-entreprise .field-collection-view .item .description .content {
  max-width: 320px;
  display: flex;
  flex-direction: column; }

.main-entreprise .field-collection-view .item .description .annee {
  color: #b19650;
  margin-bottom: 16px;
  font: 400 65px/60px 'Dancing Script', cursive; }

.main-entreprise .field-collection-view .item .description .titre {
  font-size: 16px;
  color: #b19650;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 8px; }

.main-entreprise .field-collection-view .item .description .histoiredescription {
  font-size: 15px;
  color: #fff;
  line-height: 28px; }

/****************************ENZO**************************/
/*********ACCUEIL*********/
li.leaf {
  padding: 0; }

.home-criteres .item.contentbloc:hover span.title {
  transition: all 0.2s;
  opacity: 0; }

.home-criteres .item.contentbloc:hover span.title_hover {
  transition: all 0.4s 0.1s;
  opacity: 1; }

.home-criteres .item span.title_hover {
  opacity: 0;
  transition: opacity 0.25s;
  color: black;
  font-size: 16px;
  padding: 4px;
  text-transform: uppercase;
  font-family: NexaRustSans-Book;
  background-color: #b19650;
  position: absolute;
  width: 95%;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  height: auto; }

.home-criteres .item.contentbloc span.bg_title_hover {
  border: 1px solid #000;
  padding: 0px;
  width: 100%;
  display: block;
  margin-top: 0; }

/*.nolink::after {
    content: " |";
    margin-left: 0.5rem;
}*/
.menu-secondaire ul.menu li.leaf a:after, .menu-secondaire ul.menu li.leaf span:after {
  content: " |";
  margin-left: 0.5rem; }

.menu-secondaire ul.menu li.leaf.last a:after {
  display: none; }

ul.menu li {
  margin: 0 0 0 1.7em; }

li.expanded, li.collapsed, li.leaf {
  padding: 0; }

/*********NOS MARQUES*********/
.view-nos-marques {
  background-color: #fff; }

body.page-nos-marques .owl-nav {
  display: none; }

span.field-content div.itemmarque div.top {
  display: flex;
  align-items: center;
  max-height: 300px;
  overflow: hidden;
  background-color: #fff; }

span.field-content div.itemmarque div.top div.logo {
  flex: 1;
  text-align: center;
  padding: 0 10px; }

span.field-content div.itemmarque div.top div.logo img {
  width: 250px; }

span.field-content div.itemmarque div.top div.bandeau {
  flex: 3; }

span.field-content div.itemmarque div.top div.bandeau img {
  width: 100%; }

span.field-content div.itemmarque div.bottom {
  display: flex; }

span.field-content div.itemmarque div.bottom div.meta {
  flex: 0.67 0 0%;
  text-align: center;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center; }

span.field-content div.itemmarque div.bottom div.meta li, span.field-content div.itemmarque div.bottom div.meta p {
  font-size: 13px;
  font-family: 'Work Sans';
  color: #706f6f; }

span.field-content div.itemmarque div.bottom div.meta li::before {
  display: none; }

span.field-content div.itemmarque div.bottom div.meta li + li {
  margin-left: 5px; }

span.field-content div.itemmarque div.bottom div.meta ul, span.field-content div.itemmarque div.bottom div.meta p {
  align-items: center;
  padding: 0 20%;
  display: inline-table; }

span.field-content div.itemmarque div.bottom div.description {
  flex: 2 0 0%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  padding: 50px 0;
  color: #000000; }

span.field-content div.itemmarque div.bottom div.description div.texte {
  flex: 2; }

span.field-content div.itemmarque div.bottom div.description div.image {
  flex: 1; }

span.field-content div.itemmarque div.bottom div.description h2 {
  font-size: 15px;
  font-family: 'NexaRustSans-Black';
  width: 60%; }

span.field-content div.itemmarque div.bottom div.description p {
  font-size: 13px;
  font-family: 'Work Sans';
  width: 90%;
  margin-top: 1.5rem;
  line-height: 34px; }

span.field-content div.itemmarque div.bottom div.description p.more {
  text-align: right; }

span.field-content div.itemmarque div.bottom div.description p.more a {
  font: normal 32px 'Dancing Script', cursive;
  color: #000000;
  background: url(../images/fleche-decouvrir-produits.png) no-repeat right center;
  padding: 10px 35px;
  background-size: 16px; }

span.field-content div.itemmarque div.bottom div.description p.more a:hover {
  background-position: right 7px center;
  background-size: 13px; }

/*********PRODUIT*********/
.view-produits {
  background-color: #b09650;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  align-items: left;
  background-color: #b09650; }

.view-produits .views-row {
  width: 33.33333%;
  text-align: center;
  border-bottom: 2px solid #000;
  border-right: 2px solid #000;
  padding: 5px;
  background-color: #b19650; }

.view-produits .views-row:nth-child(3n+1) {
  border-left: 2px solid #000;
  padding-top: 7px; }

/*.view-produits .views-row .bloc_produit{position :relative ;}

.view-produits .views-row .bloc_produit .bloc_text{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 215px;
    left: 0;
    right: 0;
}*/
.view-produits .view-filters {
  width: 25%;
  min-height: 80vh;
  background-color: #b19650;
  padding: 0 2rem 0 2rem;
  color: #000000; }

.view-produits .view-empty {
  margin-top: 135px;
  font-size: 20px;
  text-align: center;
  width: 75%; }

.view-produits .view-content .views-row .bloc_produit {
  position: relative;
  overflow: hidden; }

.view-produits .view-content .views-row .bloc_produit .bloc_text {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%; }

.view-produits .view-content .views-row .bloc_produit .bloc_text .readmore a {
  display: flex;
  background-color: #b19650;
  color: black;
  height: 40px;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  transform: translateY(60px);
  transition: all 0.4s ease;
  font-size: 15px; }

.view-produits .view-content .views-row:hover .bloc_produit .bloc_text .readmore a {
  transform: translateY(0); }

.frmCustomSearch {
  background-color: black; }

div.views-exposed-form {
  padding: 0 1.5rem 0 2rem; }

.views-exposed-form .views-exposed-widget {
  float: none; }

.view-produits .view-content {
  flex: 1;
  width: 75%;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  margin-bottom: 20px;
  align-items: flex-start; }

.view-produits .item-list {
  width: 100%;
  background-color: black; }

.views-exposed-widgets label:first-child {
  font: 400 42px 'Dancing Script', cursive;
  margin-top: 50px; }

.form-item label {
  font-size: 14px;
  font-weight: 400;
  font-family: 'NexaRustSans-Book';
  text-transform: uppercase;
  margin-left: 0.6rem; }

.form-item input:checked ~ label {
  color: white; }

.form-item input:checked ~ label:after {
  content: " >"; }

.form-item input:hover ~ label {
  color: white; }

.form-item input:hover ~ label:after {
  content: " >"; }

.form-item input {
  cursor: pointer; }

.form-item label {
  cursor: pointer; }

.bef-checkboxes {
  margin-top: 0.8rem; }

.button-reset {
  text-transform: uppercase;
  text-decoration: underline;
  color: black;
  font-size: 14px;
  font-weight: 400;
  font-family: 'NexaRustSans-Book'; }

.button-reset p {
  line-height: 18px;
  width: auto;
  padding: 50px 0px 50px 30px;
  font-weight: 300; }

div.title-form {
  float: left;
  margin: 2.5rem 0 3rem 0;
  width: 100%; }

div.title-form p {
  font-size: 26px;
  font-family: 'NexaRustSans-Book';
  font-weight: 100;
  float: left;
  margin-left: 0.8rem; }

div.title-form img {
  height: 1.8rem;
  float: left; }

div.bloc_produit .nouveaute {
  position: absolute;
  right: 3px;
  top: 3px;
  font-size: 0;
  width: 100px;
  height: 100px; }

div.bloc_produit .nouveaute:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(../images/cartouche.png) no-repeat center center;
  background-size: cover; }

[lang=en-gb] div.bloc_produit .nouveaute:after {
  background-image: url(../images/cartouche-en.png); }

div.bloc_produit p {
  font-size: 17px;
  line-height: initial;
  font-weight: bold; }

div.bloc_produit p.marque {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  font-size: 17px;
  font-weight: normal; }

div.bloc_produit p.marque:before, div.bloc_produit p.marque:after {
  content: "";
  display: block;
  width: 3px;
  height: 3px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 9px; }

div.bloc_produit p.marque:before {
  left: -12px; }

div.bloc_produit p.marque:after {
  right: -12px; }

div.bloc_produit img {
  width: 100%; }

div.bloc_produit img {
  width: 100%; }

.views-exposed-form .form-item .disabled label {
  color: #7b693b; }

.views-exposed-form .form-item .disabled label, .views-exposed-form .form-item .disabled input[type=checkbox] {
  cursor: none; }

.views-exposed-form .form-item .disabled input[type=checkbox]:after {
  border: 2px solid #7b693b; }

.views-exposed-form .form-item .disabled input:hover ~ label:after {
  content: ""; }

/*------------CHECKBOX----------------*/
input[type=checkbox] {
  position: relative; }

input[type=checkbox]:after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #b19650;
  border: 2px solid #534726;
  border-radius: 3px;
  transition: all 0.3s ease; }

input[type=checkbox] ~ label {
  transition: all 0.3s ease; }

input[type=checkbox]:hover:after, input[type=checkbox]:checked:after {
  border: 2px solid #dacead; }

input[type=checkbox]:before {
  content: "";
  background: url(../images/check.png) no-repeat center center;
  width: 17px;
  height: 17px;
  position: absolute;
  z-index: 2;
  top: -3px;
  left: 1px;
  display: none; }

input[type=checkbox]:checked:before {
  display: block; }

.home-criteres .item.contentbloc + .item.contentbloc {
  border-left: 2px solid #000; }

/*----------------------- Actualités-----------------*/
.page-nos-actualites .view-actualites {
  padding-top: 115px;
  background: url(../images/pattern01.jpg) repeat center center;
  padding-bottom: 80px; }

/*.page-nos-actualites .view-actualites .view-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}*/
.page-nos-actualites .view-actualites .views-row {
  width: 1000px;
  margin: 15px auto;
  box-sizing: border-box;
  background-color: #fff; }

.page-nos-actualites .view-actualites.homeactu .views-row {
  width: auto;
  margin: none;
  border: none;
  box-sizing: border-box;
  background-color: transparent; }

.page-nos-actualites .view-actualites .views-row .itemblog {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 25px; }

.page-nos-actualites .view-actualites .views-row .image {
  flex: 1; }

.page-nos-actualites .view-actualites .views-row .contenu {
  flex: 3;
  font: 400 15px/30px 'Work Sans', sans-serif;
  color: #000;
  padding: 25px; }

.page-nos-actualites .view-actualites .views-row .contenu h2 {
  font: 300 24px 'Quattrocento', serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #b19650;
  position: relative;
  margin-bottom: 15px; }

.page-nos-actualites .view-actualites .views-row .contenu .accroche p {
  font-family: 'NexaRustSans-Black';
  color: #b19650;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 28px; }

.page-nos-actualites .view-actualites .views-row .contenu .accroche p.rteright {
  display: none; }

.page-nos-actualites .view-actualites .views-row .contenu a {
  display: inline-block;
  color: #b19650;
  text-decoration: underline; }

.item-list .pager {
  clear: both;
  display: block;
  margin: auto;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 30px; }

.item-list .pager ul {
  display: table;
  margin: auto;
  border-collapse: separate; }

.item-list .pager li {
  display: inline-block;
  padding-right: 5px;
  padding-left: inherit;
  text-indent: inherit;
  margin: 0;
  padding: 0; }

.item-list .pager li::before {
  content: none; }

.item-list .pager li.pager-current, .pager li a {
  width: 45px;
  height: 45px;
  display: block;
  font-weight: 700;
  font-size: 17px;
  line-height: 45px;
  text-align: center;
  border: none;
  box-shadow: none;
  background-image: none; }

.item-list .pager li.pager-current {
  display: inline-block; }

.item-list .pager li a {
  background: #f9f9f9;
  text-align: center;
  color: #000;
  -webkit-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out; }

.item-list .pager li a:hover {
  background: #b19650;
  color: #fff; }

.item-list .pager li.pager-current {
  background: #b19650;
  color: #fff; }

.item-list .pager .pagination-prev, .item-list .pager .pagination-next {
  display: none; }

/*.pager li{
    background-color: #b19650;
    box-sizing: border-box;
    padding: 8px;
}

.pager li a{.en-tete
    color: #fff;
}
*/
article .en-tete {
  background-repeat: no-repeat;
  position: relative; }

body.node-type-page article .en-tete, body.node-type-webform article .en-tete, body.node-type-actualite article.actualites .en-tete, body.page-nos-actualites .en-tete {
  min-height: 500px;
  position: relative; }

body.node-type-page .search, body.node-type-actualite .search, body.node-type-webform .search {
  top: 78px; }

body.page-produits header, body.page-nos-produits header {
  position: relative;
  background-color: #000; }

/*.search {position: absolute; left: 0; width: 100%;} */
body.page-produits header.fix, body.page-nos-produits header.fix, body.page-nos-actualites header.fix {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; }

.search .flex {
  align-items: center; }

body.page-produits .search, body.page-nos-produits .search {
  position: relative;
  top: 0; }

.search .wrap .frmCustomSearch #submit-box {
  display: none; }

body.page-user {
  height: 110px;
  position: relative; }

body.page-user form#user-login {
  width: 500px;
  margin: 100px auto; }

body.page-user form#user-login input[type=text], body.not-logged-in form#user-login input[type=password] {
  background-color: #fff;
  height: 38px;
  border-radius: 0;
  width: 100%;
  font: 400 15px/30px 'Work Sans', sans-serif;
  padding: 5px 10px;
  border: 1px solid #c4c4c4; }

body.page-user form#user-login input[type=submit] {
  width: 170px;
  font-size: 25px;
  font-weight: normal;
  background-color: #b19650;
  margin-bottom: 20px;
  float: right;
  color: #fff;
  height: 45px;
  margin: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  font: 400 20px/30px 'Work Sans', sans-serif;
  cursor: pointer;
  text-transform: uppercase; }

article .en-tete .filterbandeau, .bandeau .filterbandeau {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0; }

.bandeau .filterbandeau {
  height: 600px; }

article .en-tete .filterbandeau img, .bandeau .filterbandeau img {
  width: 100%;
  height: 550px; }

body.node-type-page-entreprise article .en-tete .filterbandeau {
  height: 550px; }

.search .wrap .frmSearch #suggestion-box ul {
  position: absolute;
  top: 38px;
  background-color: #fff;
  width: 25vw;
  color: #000;
  padding: 15px;
  left: 0; }

.search .wrap .frmSearch #suggestion-box ul li {
  transition: all 0.3s ease;
  cursor: pointer; }

.search .wrap .frmSearch #suggestion-box ul li:hover {
  background-color: #f7f7f7; }

.slide-accueil ul.field-collection-container .owl-stage li {
  height: 100%;
  width: 100%; }

.diaporama .slide-accueil .field-collection-view .logos {
  z-index: 999;
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center; }
  .diaporama .slide-accueil .field-collection-view .logos a + a {
    margin-left: 10px; }
  .diaporama .slide-accueil .field-collection-view .logos img {
    max-width: 200px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 15px;
    height: 150px;
    object-fit: contain;
    width: 100%;
    justify-content: center;
    margin-top: 300px;
    flex: 1 0 0; }
    .diaporama .slide-accueil .field-collection-view .logos img + img {
      margin-left: 20px; }

/*----------------ANIM----------------*/
.anim {
  transform: translateY(80px);
  -webkit-transform: translateY(80px);
  -moz-transform: translateY(80px);
  -o-transform: translateY(80px);
  opacity: 0;
  transition: all 1s ease; }

.anim.active {
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  opacity: 1; }


